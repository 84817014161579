<template>
  <div>
    <main-header></main-header>
    <breadcrumbs></breadcrumbs>

    <div class="wrapper wrapper--full wrapper--grey">
      <div class="wrapper">
        <div class="grid grid--usedcars">
          <div class="usedcars_flex_item usedcars_flex_item--left">
            <refine-search></refine-search>
          </div>
          <div class="usedcars_flex_item usedcars_flex_item--right">
            <car-listings :slug="mySlug"></car-listings>
          </div>
        </div>

        <refine-search-mobile></refine-search-mobile>
        <refine-widgets-desktop></refine-widgets-desktop>
      </div>
    </div>


    <main-footer></main-footer>
  </div>
</template>
<script>
    // @ is an alias to /src
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'
    import RefineSearch from '@/components/partials/RefineSearch'
    import RefineWidgetsDesktop from '@/components/partials/RefineWidgetsDesktop'
    import RefineSearchMobile from '@/components/partials/RefineSearchMobile'
    import CarListings from '@/components/partials/CarListings'
    import {mapMutations, mapState, mapActions} from "vuex"
    import {type} from "mocha/lib/utils";
    // import $ from 'jquery'
    // import NativeEventBus from "../native-event-bus"

    export default {
        name: 'UsedCars',
        components: {
            MainHeader,
            MainFooter,
            Breadcrumbs,
            RefineSearch,
            RefineWidgetsDesktop,
            RefineSearchMobile,
            CarListings
        },
        props: {
            slug: {
                type: String,
                required: false
            }
        },
        data: function () {
            return {
                loadRefineSearchMobile: false,
                fetchIndex:0,
                requestedPage: 1,
                pagination: {},
                currentPage: 0,
                from: 0,
                lastPage: 0,
                to: 0,
                perPage: 0,
                total: 0,
                urlParams: null,
                mySlug: this.slug == null ? 'used-cars' : this.slug,
                title: "Used Cars, Cars in Ireland, Electric Cars, 7 Seaters, SUV’s | Terrific.ie",
                description: "Terrific.ie details used cars for sale in Ireland including electric cars, 7 seaters and SUV’s.",
                keywords: "Used Cars, Cars for Sale, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters,  Land Rover, People Carriers",
                robots: "all"
            }
        },
        beforeRouteUpdate (to, from, next) {
            this.resetSearchVars();
            this.urlParams = to.params.slug;
            this.setCurrentPageName('usedCars');
            this.urlParams = typeof this.urlParams != 'undefined' ? this.urlParams : 'used-cars';
            this.fetchIndex = this.fetchIndex++;

            if(this.getCookie("g") == 1){
                this.$store.commit('setDealerGroup', 1);
            }

            this.fetchSearchResults(this.urlParams);
            if(to.fullPath == '/used-cars'){
                this.$store.commit('setCurrentPage', 1);
                this.$store.commit('setRequestedPage', 1);
            }
            next()
        },
        beforeRouteLeave (to, from, next) {
            next()
        },
        computed: {
            ...mapState([
                'makes',
                'models',
                'years',

                'prices',
                'deposits',
                'monthlyPrices',
                'locations',
                'mileageKilometers',
                'mileages',
                'kilometres',
                'engineSizes',
                'transmissionTypes',
                'fuelTypes',
                'bodyTypes',
                'colours',
                'seats',
                'doors',
                'safetyRatings',
                'fuelConsumption',
                'environmental',
                'enginePowers',
                'accelerations',

                'searchMake',
                'searchModel',
                'searchYearFrom',
                'searchYearTo',
                'searchPriceFrom',
                'searchPriceTo',
                'searchFinanceDeposit',
                'searchMonthlyPricesFrom',
                'searchMonthlyPricesTo',
                'searchLocations',
                'searchMileageFrom',
                'searchMileageTo',
                'searchKilometresFrom',
                'searchKilometresTo',
                'searchEngineSizeFrom',
                'searchEngineSizeTo',
                'searchTransmissionType',
                'searchFuelType',
                'searchBodyType',
                'searchColour',
                'searchSeats',
                'searchDoors',
                'searchSafetyRating',
                'searchFuelConsumption',
                'searchEnvironmental',
                'searchEnginePower',
                'searchAcceleration',
                'resultsLoaded',
                'searchResults',
            ]),
            // display the item from store state.
            searchResultData () {
                this.$store.commit('ads/setError404', false);
                return this.$store.state.searchResults[this.mySlug];
            },
            maxPages(){
                return parseInt(process.env.VUE_APP_MAX_PAGES);
            },
            yearSet() {
                return (this.searchYearFrom != null || this.searchYearTo != null);
            },
            page: {
                get() {
                    return parseInt(this.$store.state.currentPage);
                },
                set(value) {
                    this.$store.commit('setCurrentPage', value);
                }
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            formatagg: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.replace(/_/g, ' ')
            },
            formataggdash: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.replace(/_/g, '-')
            }
        },
        // Server-side only
        // This will be called by the server renderer automatically
        serverPrefetch () {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            this.mySlug = this.slug == null ? 'used-cars' : this.slug;
            // return the Promise from the action
            // so that the component waits before rendering
            let res = this.fetchSearchResults(this.mySlug);
            let canonical = this.getCanonical();
            if(canonical){
                this.$ssrContext.canonical = process.env.VUE_APP_BASE_URL + canonical;
            }
            return res;
        },
        mounted() {
            this.mySlug = this.slug == null ? 'used-cars' : this.slug;
            // this.setCurrentPage(this.$route.query.page || 1);
            this.setCurrentPageName('usedCars');

            // Very important to avoid breaking the headers.
            setTimeout(() => {
                this.loadRefineSearchMobile = true;
            }, 100);

            // this.resetSearchVars();
            if (this.searchResultData == null) {
                this.resetSearchVars();
                this.fetchSearchResults(this.mySlug);
            }else{

                if(this.getCookie("g") == 1){
                    this.$store.commit('setDealerGroup', 1);
                }

                 this.setTotal(this.searchResultData.hits.total.value);
                 this.setTo(this.searchResultData.pagination.to);
                 this.setFrom(this.searchResultData.pagination.from);
                 this.setPerPage(this.searchResultData.pagination.perPage);
                 this.setCurrentPage(this.searchResultData.pagination.currentPage);

            }

            let that = this;
            window.addEventListener('popstate', function(event) {
                // that.setRequestedPage(2);
                // that.setCurrentPage(2);

                // that.search();
               // alert(1);
            });

            this.checkEmptyMake();
            this.checkEmptyModel();
            this.updateBreadCrumb();

        },
        methods: {
            ...mapMutations([
                'setMakes',
                'setModels',
                'setYears',
                'setYearsFrom',
                'setYearsTo',
                'setPrices',
                'setPricesFrom',
                'setPricesTo',
                'setLocations',
                'setCounties',
                'setMileages',
                'setMileagesFrom',
                'setMileagesTo',
                'setKilometres',
                'setKilometresFrom',
                'setKilometresTo',
                'setEngineSizes',
                'setEngineSizesFrom',
                'setEngineSizesTo',
                'setTransmissionTypes',
                'setFuelTypes',
                'setBodyTypes',
                'setColours',
                'setSeats',
                'setDoors',
                'setMonthlyPrices',
                'setMonthlyPricesFrom',
                'setMonthlyPricesTo',
                'setSafetyRatings',
                'setFuelConsumptions',
                'setEnvironmentals',
                'setEnginePowers',
                'setAccelerations',
                'setFinanceDeposits',
                'setOpenMobileRefine',
                'setSearchMake',
                'setSearchMakeDesktop',
                'unsetSearchMake',
                'setSearchModel',
                'setSearchModelDesktop',
                'unsetSearchModel',
                'setSearchYearFrom',
                'setSearchYearTo',
                'setSearchPriceFrom',
                'setSearchPriceTo',
                'setSearchLocations',
                'setSearchMileageFrom',
                'setSearchMileageTo',
                'setSearchKilometresFrom',
                'setSearchKilometresTo',
                'setSearchMonthlyPricesFrom',
                'setSearchMonthlyPricesTo',
                'setSearchEngineSizeFrom',
                'setSearchEngineSizeTo',
                'setSearchTransmissionType',
                'setSearchFuelType',
                'setSearchBodyType',
                'setSearchColour',
                'setSearchSeats',
                'setSearchDoors',
                'setSearchSafetyRating',
                'setSearchFuelConsumption',
                'setSearchEnvironmental',
                'setSearchEnginePower',
                'setSearchAcceleration',
                'setSearchFinanceDeposit',
                'setCurrentFilter',
                'setSearchOrderBy',
                'setRequestedPage',
                'setSearchFilters',
                'setInternalSource',
                'setJustLoaded',
                'setUrlParams',
                'setSearchResults',
                'setBackendPagination',
                'setCurrentPageName',
                'setCurrentPage',
                'setPerPage',
                'setTotal',
                'setFrom',
                'setTo',
                'setResultsLoaded',
                'setUrl',
                'setIsLoading',
                'resetSearchVars',
                'setPagination',
            ]),
            ...mapActions([
                'syncLoadMakesAction',
                'updateUrlAction'
            ]),
            getCookie (name) {
                if(typeof document !== 'undefined') {
                    let value = `; ${document.cookie}`;
                    let parts = value.split(`; ${name}=`);
                    if (parts.length === 2) return parts.pop().split(';').shift();
                }
            },
            getCanonical(){

                let frontendUrl = this.$router.currentRoute.fullPath.replace("/s-1", '');
                let res = null;
                let arrCanonical = {
                    '/used-cars/safetyratings-5' : '/used-cars/5-star-safety',
                    '/used-cars/seats-7' : '/used-cars/7-seaters',
                    '/used-cars/seats-8' : 'used-cars/8-plus-seaters',
                    'used-cars/transmissiontypes-automatic' : '/used-cars/automatics',
                    '/used-cars/yearto-1990' : '/used-cars/classic',
                    '/used-cars/environmental-5' : '/used-cars/eco-friendly',
                    '/used-cars/fueltypes-electric' : '/used-cars/electric',
                    '/used-cars/accelerations-0+8' : '/used-cars/fast',
                    '/used-cars/hybrids' : '/used-cars/hybrid',
                    '/used-cars/enginepowers-70+100' : '/used-cars/low-running-costs',
                    '/used-cars/pricefrom-70000' : '/used-cars/luxury',
                    '/used-cars/enginepowers-250+300' : '/used-cars/powerful',
                    '/used-cars/makes-mini-fiat-volkswagen/models-500l-500x-500-beetle-cooper-one' : '/used-cars/pretty',
                    '/used-cars/bodytypes-suv':'/used-cars/suv',
                    '/used-cars/bodytypes-van':'/used-cars/vans',
                    '/used-cars/bodytypes-wheelchair+accessible':'/used-cars/wheelchair'
                };

                if(typeof arrCanonical[frontendUrl] !== 'undefined' ){
                    res = arrCanonical[frontendUrl];
                }

                return res;
            },
            reloadSearch(){
                this.resetSearchVars();
                this.fetchSearchResults(this.mySlug);
            },
            async fetchSearchResults (url) {
                // return the Promise from the action

                if( typeof document !== 'undefined' && this.getCookie("g") == 1 ) {
                    this.$store.commit('setDealerGroup', 1);
                }

                let res = await this.$store.dispatch('fetchSearchResults', url);
                this.updateBreadCrumb();
                this.fetchIndex = 0;
                return res;
            },
            updateVueRouter() {
                let newQueryString = '/' + this.$router.currentRoute.name + '/' + Object.keys(this.$store.state.urlParams).map(key => key + '-' + this.$store.state.urlParams[key]).join('/');
                let currentQueryString = this.$router.currentRoute.fullPath;
                if (newQueryString != currentQueryString) {
                    this.$router.push({path: newQueryString.toLowerCase()});
                }
            },
            clearPagination() {
                this.setRequestedPage(1);
                this.$route.query.page = null;
            },
            findMake(){
                let res = null;
                if(this.$store.state.searchMakeDesktop.length == 1 && this.$store.state.makes != null){
                    res = this.$store.state.makes.filter(search => search.id == this.$store.state.searchMakeDesktop[0]);
                    res = res[0] ? res[0].labelDesktop : '';
                }
                return res;
            },
            findModel(){
                let res = null;
                if(this.$store.state.searchModelDesktop.length == 1 && this.$store.state.models != null){
                    res = this.$store.state.models.filter(search => search.id == this.$store.state.searchModelDesktop[0]);
                    res = res[0] ? res[0].labelDesktop : '';
                }
                return res;
            },
            findYear(){
                let res = null;
                if(this.$store.state.searchYearFrom == this.$store.state.searchYearTo){
                    res = this.$store.state.searchYearTo;
                }
                return res;
            },
            checkEmptyMake(){
                if(this.$store.state.searchMakeDesktop.length == 1){
                    if(this.makes != null) {
                        let res = this.makes.filter(item => item.id == this.$store.state.searchMakeDesktop[0]);
                        if(res.length == 0){
                            this.$router.push({path:'/used-cars/'});
                        }
                    }
                }
            },
            checkEmptyModel(){
                if(this.$store.state.searchModelDesktop.length == 1) {
                    if (this.models != null) {
                        let res = this.models.filter(item => item.id == this.$store.state.searchModelDesktop[0]);
                        if (res.length == 0) {
                            let make = '';
                            if(this.$store.state.searchMakeDesktop.length > 0){
                                make = 'makes-' + this.$store.state.searchMakeDesktop.join('-');
                            }
                            this.$router.push({path:'/used-cars/' + make });
                        }
                    }
                }
            },
            updateBreadCrumb(){
                let make = this.findMake();
                let model = this.findModel();
                let year = this.findYear();

                if(make && !model && !year){
                    this.$store.commit('breadcrumbs/setLevelOne',  true);
                    this.$store.commit('breadcrumbs/setFirstLevelName',  "Used Cars");
                    this.$store.commit('breadcrumbs/setLevelTwo',  false);

                    this.$store.commit('breadcrumbs/setLeafLevelName',  make);

                    this.$store.commit('breadcrumbs/setLevelThree',  false);
                    this.$store.commit('breadcrumbs/setPathLevelOne',  '/used-cars');

                    this.$store.commit('breadcrumbs/setLevelFour',  false);
                    this.$store.commit('breadcrumbs/setFourthLevelName',  '');

                    this.$store.commit('breadcrumbs/setLevelFour',  false);
                }else if(make && model && !year){

                    this.$store.commit('breadcrumbs/setLevelOne',  true);
                    this.$store.commit('breadcrumbs/setFirstLevelName',  "Used Cars");
                    this.$store.commit('breadcrumbs/setLevelTwo',  true);
                    this.$store.commit('breadcrumbs/setSecondLevelName',  make);
                    this.$store.commit('breadcrumbs/setLevelThree',  false);
                    this.$store.commit('breadcrumbs/setLevelFour',  false);
                    this.$store.commit('breadcrumbs/setLeafLevelName',  model);

                    this.$store.commit('breadcrumbs/setPathLevelOne',  '/used-cars/');
                    this.$store.commit('breadcrumbs/setPathLevelTwo',  '/used-cars/makes-' + this.$store.state.searchMakeDesktop[0]);

                }else if(make && model && year){
                    this.$store.commit('breadcrumbs/setLevelOne',  true);
                    this.$store.commit('breadcrumbs/setFirstLevelName',  "Used Cars");
                    this.$store.commit('breadcrumbs/setLevelTwo',  true);
                    this.$store.commit('breadcrumbs/setSecondLevelName',  make);
                    this.$store.commit('breadcrumbs/setLevelThree',  true);
                    this.$store.commit('breadcrumbs/setThirdLevelName',  model);
                    this.$store.commit('breadcrumbs/setLeafLevelName',  year);

                    this.$store.commit('breadcrumbs/setLevelFour',  false);

                    this.$store.commit('breadcrumbs/setPathLevelOne',  '/used-cars/');
                    this.$store.commit('breadcrumbs/setPathLevelTwo',  '/used-cars/makes-' + this.$store.state.searchMakeDesktop[0]);
                    this.$store.commit('breadcrumbs/setPathLevelThree',  '/used-cars/makes-' + this.$store.state.searchMakeDesktop[0] + '/models-' + this.$store.state.searchModelDesktop[0]);

                }else{
                    this.$store.commit('breadcrumbs/setLevelOne',  false);
                    this.$store.commit('breadcrumbs/setLevelTwo',  false);
                    this.$store.commit('breadcrumbs/setLevelThree',  false);
                    this.$store.commit('breadcrumbs/setLevelFour',  false);
                    this.$store.commit('breadcrumbs/setLeafLevelName',  'Used Cars');
                }
            }
        }
    }
</script>
