<template>
    <div>
    <div class="refine__widget" data-id="refine__make"> <!-- Make -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Make</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(make, idx) in this.$store.state.makes" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox make"
                            :id="make.id"
                            :value="make.id"
                            @click="setCurFilter('makes')"
                            @change="search($event)"
                            v-model="selectedMakes"
                        />
                        <label :for="make.id" class="custom-checkbox-label"></label>
                        <label :for="make.id">
                            {{ make.labelDesktop }}
                            <span>({{ make.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter"  @click="resetFilter('makes')" data-class="refine-widget-close">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__model"> <!-- Model -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Model</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.makeModels" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="val.name"
                            :value="val.name"
                            @click="setCurFilter('models')"
                            @change="search($event)"
                            v-model="selectedModels"
                        />
                        <label :for="val.name" class="custom-checkbox-label"></label>
                        <label :for="val.name">
                            {{ val.labelDesktop.replace('_', ' ') }}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('models')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__location"> <!-- Location -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Location</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__location">
                <div class="refine__location_left" v-if="this.$store.state.locations != null && this.$store.state.locations.length > 0">
                    <ul>
                        <li v-for="(provinces, idx) in this.$store.state.locations" v-bind:key="idx">
                            <input type="checkbox"
                                   class="custom-checkbox"
                                   :id="provinces.id"
                                   :value="provinces.id"
                                   @change="searchProvince($event)"
                                   @click="setCurFilter('locations')"
                                   v-model="selectedProvinces"
                            />
                            <label :for="provinces.id" class="custom-checkbox-label"></label>
                            <label :for="provinces.id">{{provinces.customLabel}} <span>({{ provinces.docCount }})</span></label>
                        </li>
                    </ul>
                </div>
                <div class="refine__location_right">
                    <ul>
                        <li v-for="(location, idx) in this.$store.state.counties" v-bind:key="idx">
                            <input
                                type="checkbox"
                                class="custom-checkbox"
                                :id="location.id"
                                :value="location.id"
                                @change="search($event)"
                                @click="setCurFilter('locations')"
                                v-model="selectedLocations"
                            />
                            <label :for="location.id" class="custom-checkbox-label"></label>
                            <label :for="location.id">
                                {{location.id | capitalize}}
                                <span>({{ location.docCount }})</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('locations')">Clear filters</button>
                <button data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__gearbox"> <!-- Gearbox -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Gearbox</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.transmissionTypes" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="'tt_' + val.id"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('gearbox')"
                            v-model="selectedTransmissionTypes"
                        />
                        <label :for="'tt_' + val.id" class="custom-checkbox-label"></label>
                        <label :for="'tt_' + val.id">
                            {{val.labelDesktop}}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('transmission-types')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__fueltype"> <!-- Fuel Type -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Fuel Type</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.fuelTypes" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="'ft_'+val.id"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('fuelType')"
                            v-model="selectedFuelTypes"
                        />
                        <label :for="'ft_'+val.id" class="custom-checkbox-label"></label>
                        <label :for="'ft_'+val.id">
                            {{val.labelDesktop }}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('fuel-types')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__bodytype"> <!-- Body Type -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Body Type</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.bodyTypes" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="'bt_' + val.id"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('bodyType')"
                            v-model="selectedBodyTypes"
                        />
                        <label :for="'bt_' + val.id" class="custom-checkbox-label"></label>
                        <label :for="'bt_' + val.id">
                            {{val.labelDesktop }}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('body-types')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__colour"> <!-- Colour -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Colour</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.colours" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="val.id"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('colour')"
                            v-model="selectedColours"
                        />
                        <label :for="val.id" class="custom-checkbox-label"></label>
                        <label :for="val.id">
                            {{val.labelDesktop }}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('colours')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__seats"> <!-- Seats -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Seats</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.seats" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="val.id+'seat'"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('seats')"
                            v-model="selectedSeats"
                        />
                        <label :for="val.id+'seat'" class="custom-checkbox-label"></label>
                        <label :for="val.id+'seat'">
                            {{val.labelDesktop}}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('seats')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__doors"> <!-- Doors -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Doors</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.doors" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="val.id+'door'"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('doors')"
                            v-model="selectedDoors"
                        />
                        <label :for="val.id+'door'" class="custom-checkbox-label"></label>
                        <label :for="val.id+'door'">
                            {{val.labelDesktop}}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('doors')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__safety"> <!-- Safety Rating -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Safety Rating</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.safetyRatings" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="val.id+'safetyRating'"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('safetyRatings')"
                            v-model="selectedSafetyRatings"
                        />
                        <label :for="val.id+'safetyRating'" class="custom-checkbox-label"></label>
                        <label :for="val.id+'safetyRating'">
                            {{val.labelDesktop}}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('safety-ratings')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__fuelconsumption"> <!-- Fuel Consumption -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Fuel Consumption</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.fuelConsumption" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="val.id+'fuelConsumption'"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('fuelConsumption')"
                            v-model="selectedFuelConsumptions"
                        />
                        <label :for="val.id+'fuelConsumption'" class="custom-checkbox-label"></label>
                        <label :for="val.id+'fuelConsumption'">
                            {{val.labelDesktop}}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('fuel-consumptions')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__environmental"> <!-- Environmental Rating -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Environmental Rating</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.environmental" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="val.id+'environmental'"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('environmental')"
                            v-model="selectedEnviromental"
                        />
                        <label :for="val.id+'environmental'" class="custom-checkbox-label"></label>
                        <label :for="val.id+'environmental'">
                            {{val.labelDesktop}}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('environmental')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__enginepower"> <!-- Engine Power -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Engine Power</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.enginePowers" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="val.id+'enginePowers'"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('enginePowers')"
                            v-model="selectedEnginePower"
                        />
                        <label :for="val.id+'enginePowers'" class="custom-checkbox-label"></label>
                        <label :for="val.id+'enginePowers'">
                            {{val.labelDesktop}}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('engine-powers')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>

    <div class="refine__widget" data-id="refine__acceleration"> <!-- Acceleration -->
        <div class="refine__panel">
            <div class="refine__panel_pointer"></div>
            <h1>Select Acceleration</h1>
            <div class="refine__panel_close" data-class="refine-widget-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.707" height="20.707" viewBox="0 0 20.707 20.707">
                    <g id="close" transform="translate(-739.646 -19.646)">
                        <path id="Path_3098" data-name="Path 3098" d="M18315.078-15530.864l20,20" transform="translate(-17575.078 15550.864)" fill="none" stroke="#707070" stroke-width="1"/>
                        <path id="Path_3099" data-name="Path 3099" d="M18315.076-15511.18l20-20" transform="translate(-17575.076 15551.179)" fill="none" stroke="#707070" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div class="refine__checkboxes">
                <ul>
                    <li v-for="(val, idx) in this.$store.state.accelerations" v-bind:key="idx">
                        <input
                            type="checkbox"
                            class="custom-checkbox"
                            :id="val.id+'accelerations'"
                            :value="val.id"
                            @change="search($event)"
                            @click="setCurFilter('accelerations')"
                            v-model="selectedAcceleration"
                        />
                        <label :for="val.id+'accelerations'" class="custom-checkbox-label"></label>
                        <label :for="val.id+'accelerations'">
                            {{val.labelDesktop}}
                            <span>({{ val.docCount }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="refine__buttons">
                <button class="close btn-clear-filter" @click="resetFilter('accelerations')">Clear filters</button>
                <button class="close" data-class="refine-widget-close">Close</button>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import $ from 'jquery'

    export default {
        name: "RefineWidgetsDesktop",
        computed: {
            total: {
                get() {
                    return this.$store.state.total;
                },
                set(value){
                    this.$store.commit('setTotal', value);
                }
            },
            selectedMakes: {
                get() {
                    return this.$store.state.searchMakeDesktop;
                },
                set(value) {
                    this.$store.commit('setSearchMakeDesktop', value);
                }
            },
            selectedModels: {
                get() {
                    return this.$store.state.searchModelDesktop;
                },
                set(value) {
                    this.$store.commit('setSearchModelDesktop', value);
                }
            },
            selectedLocations: {
                get() {
                    return this.$store.state.searchLocations;
                },
                set(value) {
                    this.$store.commit('setSearchLocations', value);
                }
            },
            selectedProvinces: {
                get() {
                    return this.$store.state.searchProvinces;
                },
                set(value) {
                    this.$store.commit('setSearchProvinces', value);
                }
            },
            selectedTransmissionTypes: {
                get() {
                    return this.$store.state.searchTransmissionType;
                },
                set(value) {
                    this.$store.commit('setSearchTransmissionType', value);
                }
            },
            selectedFuelTypes: {
                get() {
                    return this.$store.state.searchFuelType;
                },
                set(value) {
                    this.$store.commit('setSearchFuelType', value);
                }
            },
            selectedBodyTypes: {
                get() {
                    return this.$store.state.searchBodyType;
                },
                set(value) {
                    this.$store.commit('setSearchBodyType', value);
                }
            },
            selectedColours: {
                get() {
                    return this.$store.state.searchColour;
                },
                set(value) {
                    this.$store.commit('setSearchColour', value);
                }
            },
            selectedSeats: {
                get() {
                    return this.$store.state.searchSeats;
                },
                set(value) {
                    this.$store.commit('setSearchSeats', value);
                }
            },
            selectedDoors: {
                get() {
                    return this.$store.state.searchDoors;
                },
                set(value) {
                    this.$store.commit('setSearchDoors', value);
                }
            },
            selectedSafetyRatings: {
                get() {
                    return this.$store.state.searchSafetyRating;
                },
                set(value) {
                    this.$store.commit('setSearchSafetyRating', value);
                }
            },
            selectedFuelConsumptions: {
                get() {
                    return this.$store.state.searchFuelConsumption;
                },
                set(value) {
                    this.$store.commit('setSearchFuelConsumption', value);
                }
            },
            selectedEnviromental: {
                get() {
                    return this.$store.state.searchEnvironmental;
                },
                set(value) {
                    this.$store.commit('setSearchEnvironmental', value);
                }
            },
            selectedEnginePower: {
                get() {
                    return this.$store.state.searchEnginePower;
                },
                set(value) {
                    this.$store.commit('setSearchEnginePower', value);
                }
            },
            selectedAcceleration: {
                get() {
                    return this.$store.state.searchAcceleration;
                },
                set(value) {
                    this.$store.commit('setSearchAcceleration', value);
                }
            }
        },
        methods: {
            resetFilter(filter){

                switch (filter){
                    case 'makes':
                        this.$store.commit('setSearchMakeDesktop', []);
                        break;
                    case 'models':
                        this.$store.commit('setSearchModelDesktop', []);
                        break;
                    case 'locations':
                        const checkboxes = document.querySelectorAll('.custom-checkbox');
                        checkboxes.forEach((checkbox) => {
                            checkbox.checked = false;
                        });
                        this.$store.commit('setSearchLocations', []);
                        break;
                    case 'transmission-types':
                        this.$store.commit('setSearchTransmissionType', []);
                        break;
                    case 'fuel-types':
                        this.$store.commit('setSearchFuelType', []);
                        break;
                    case 'body-types':
                        this.$store.commit('setSearchBodyType', []);
                        break;
                    case 'colours':
                        this.$store.commit('setSearchColour', []);
                        break;
                    case 'seats':
                        this.$store.commit('setSearchSeats', []);
                        break;
                    case 'doors':
                        this.$store.commit('setSearchDoors', []);
                        break;
                    case 'safety-ratings':
                        this.$store.commit('setSearchSafetyRating', []);
                        break;
                    case 'fuel-consumptions':
                        this.$store.commit('setSearchFuelConsumption', []);
                        break;
                    case 'environmental':
                        this.$store.commit('setSearchEnvironmental', []);
                        break;
                    case 'engine-powers':
                        this.$store.commit('setSearchEnginePower', []);
                        break;
                    case 'accelerations':
                        this.$store.commit('setSearchAcceleration', []);
                        break;
                }
            },
            search(event) {
                // When search for make, than clear all models (same as carzone).
                if(event.target.className.includes('make')){
                    this.$store.commit('setSearchModelDesktop', []);
                }
                if(this.getCookie('g') == '1'){
                    this.$store.commit('setDealerGroup', 1);
                }
                //Internal search
                this.updateUrlAction();
                this.updateVueRouter();
            },
            setCurFilter(filter){
                this.setInternalSource('1');
                this.setCurrentFilter(filter);
                this.setRequestedPage(1);
            },
            getCookie (name) {
                let value = `; ${document.cookie}`;
                let parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            },
            searchProvince(event){
                /**
                 * set all counties selected
                 */
                let selectedProvince = event.target.value;
                let checked = event.target.checked;

                this.$store.state.locations.forEach((item) => {
                    if(item.id == selectedProvince){
                        if(checked){
                            item.children.forEach((val) => {
                                this.$store.commit('pushSearchLocations', val.id);
                            });
                        }else{

                            item.children.forEach((val) => {
                                this.$store.commit('popSearchLocations', val.id);
                            });
                        }
                    }
                });

                this.updateUrlAction();
                this.updateVueRouter();
            },
            updateVueRouter() {
                let newQueryString = '/' + this.$router.currentRoute.name + '/' + Object.keys(this.$store.state.urlParams).map(key => key + '-' + this.$store.state.urlParams[key]).join('/');
                let currentQueryString = this.$router.currentRoute.fullPath;
                if (newQueryString != currentQueryString) {
                    this.$router.push({path: newQueryString.toLowerCase()});
                }
            },
            ...mapState([
                'searchLocations',
                'searchProvinces',
                'locations',
                'counties'
            ]),
            ...mapMutations([
                'setCurrentFilter',
                'setInternalSource',
                'setRequestedPage'
            ]),
            ...mapActions([
                'updateUrlAction'
            ]),
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        mounted(){

            /**
             * jQuery Menu positioning controls
             */

            $(function() {
                'use strict'

                // Refine search toggle (mobile)
                $('[data-id=refineform]').each(function(index, parent) {
                    if ( $('[data-id=refineform-toggle]', parent).is(":checked") ) {
                        $('[data-class=refineform__hidden]', parent).addClass('refineform__hidden--show')
                    }

                    $('[data-id=refineform-toggle]', this).click((e) => {
                        if ( $(e.currentTarget).is(":checked") ) {
                            $('[data-class=refineform__hidden]', parent).addClass('refineform__hidden--show')
                        } else {
                            $('[data-class=refineform__hidden]', parent).removeClass('refineform__hidden--show')
                        }
                    })
                })

                // Refine search widgets (desktop)
                $('.refinesearch__menu li div.widget').click(function(e) {

                    e.preventDefault()

                    $('.refine__widget').removeClass('refine__widget--open')
                    $('.refinesearch__menu li div.widget').removeClass('active')

                    if (!$(e.currentTarget).hasClass('disabled')) {
                        $(e.currentTarget).addClass('active')

                        const targetId = $(e.currentTarget).data('target')
                        const targetEl = $('[data-id=' + targetId + ']')
                        const xPos = '335px'
                        const linkPosition = $(e.currentTarget).offset()
                        const yPos = linkPosition.top - 126
                        targetEl.css('left', xPos).css('top', yPos)
                        targetEl.css('opacity', 0)
                        targetEl.addClass('refine__widget--open')

                        const overflowAmount = checkWidgetOverflow()

                        const newYpos = (yPos - 5) - overflowAmount
                        targetEl.css('top', newYpos)

                        const pointerLocation = 12 + overflowAmount
                        $('.refine__panel_pointer', targetEl).css('top', pointerLocation+'px')

                        $(targetEl).animate({
                            opacity: 1,
                            left: "-=20",
                        }, 400, function() { })
                    }
                })

                const checkWidgetOverflow = () => {
                    // const widgetHeight = $(widget).height()
                    // const scrollY = window.scrollY
                    // const widgetPosition = $(widget).offset()
                    // const widgetY = widgetPosition.top
                    // const windowHeight = window.innerHeight
                    //
                    // if ( (widgetY + widgetHeight) > (scrollY + windowHeight) ) {
                    //     const overflowAmount = widgetHeight - 60
                    //     return overflowAmount
                    // }

                    return 0
                }

                $('[data-class=refine-widget-close]').click(() => {
                    $('.refinesearch__menu li div.widget').removeClass('active')
                    $('.refine__widget').removeClass('refine__widget--open')
                    $('.hotspot').hide()
                })
            })

        }
    }

</script>

<style scoped>

</style>
